.btn {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  width: 12em;
}

p {
    font-size: 1.5em;
}

.container_section_bk {
    margin-left: 2em;
    margin-right: 2em;
}

.container_section_bk:first-child {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
}

.container_section_bk:first-child > * {
    margin-top: 1em;
    margin-bottom: 1em;
}

.container_section_bk:first-child:first-child {
    margin-top: 5%;
}

.listaIncidencia {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.btnGris {
    background-color: #53575a;
}

.btnGris:hover {
    background-color: #90929a;
}

.centrar_imagen {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}
/*# sourceMappingURL=Home.css.map */
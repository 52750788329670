.list_elements_liens {
    margin-left: 10em;
}

.status-debt-active {
    color: #29bdef;
    font-weight: bold;

}

.debt-active-icon {
    position: absolute;
    margin-left: 2em;
    margin-top: -1.6em;
}

.container_title {
    margin-bottom: 3em;
}

.status-line {
    max-width: 800px;
    margin: 2em auto;
    width: 80%;
    padding: 10px;
    text-align: center;
}

.separator {
    background-color: #333;
    width: 40px;
    height: 2px;
    position: absolute;
    right: 0;
    margin-top: -0.8em;
    margin-right: -1.4em;
}

.time-left {
    color: #53575A;
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 48px;
}

.time-left-mayor {
    font-size: 64px;
    font-weight: bolder;
}

.time-left-minor {
    font-size: 22px;
    font-weight: normal;
}

.container_module .title {
    font-weight: bold;
    font-size: 24px;
    color: #53575A;
}

.icon-list-liens {
    max-width: 2em;
    margin: 1em;
}

@media only screen and (max-width: 1260px) {
    .list_elements_liens {
        margin-left: 20%;
    }
}

@media only screen and (max-width: 820px) {
    .list_elements_liens {
        margin-left: 10%;
    }
    .separator {
        width: 20px;
        margin-right: -0.7em;
        height: 1px;
    }
    .container_title {
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: 610px) {
    .list_elements_liens {
        margin-left: -1em;
    }
}

@media only screen and (max-width: 576px) {
    .list_elements_liens {
        margin-left: 24%;
    }

    .status-debt-active {
        margin-left: -20px;
    }

    .judicial {
        margin-left: 40px;
    }

    .bill {
        margin-left: 30px;
    }

    .separator {
        width: 0;
    }
}

@media only screen and (max-width: 424px) {
    .list_elements_liens {
        margin-left: 5%;
    }

    .list_elements_liens div span {
        font-size: 1.1rem;
        margin-left: 20px;
    }

    .time-left {
        font-size: 32px;
    }

    .status-debt-active {
        margin-left: -20px;
    }

    .judicial {
        margin-left: 40px;
    }

    .bill {
        margin-left: 20px;
    }
}
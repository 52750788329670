.btn_go_back {
    width: 2em;
    position: absolute;
    right: 2em;
    margin-top: -3em;
    cursor: pointer;
}

.content-resolve {
    margin-top: 5em;
}

.list_elements li {
    margin-bottom: 2em;
}

.image_woman {
    width: 128px!important;
    margin: 40px 0;
}

@media only screen and (max-width: 600px) {
    .title_module_final {
        font-size: 28px;
        padding: 0 20px;
    }
}

@media only screen and (max-width: 414px) {
    .btn_go_back {
        margin-top: -3.3em;
        right: 0.5em;
    }
}

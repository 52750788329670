.unresolved {
  position: absolute;
  left: 200px;
  top: 44px;
}

.div-apps {
  margin-top: 200px;
}

@media only screen and (max-width: 575px) {
  .unresolved {
    position: initial;
  }

  .unresolved .title {
    font-size: 32px;
  }

  .div-apps {
    margin-top: 100px;
  }
}
@import "variables.scss";

/*# sourceMappingURL=App.css.map */
.App {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	height: 100vh;
}
.body {
	width: 100%;
	max-width: 1600px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.titulo {
	color: $secondary_color_hover;
	text-align: center;
}
.subtitulo {
	color: $primary_color;
	font-weight: bolder;
}
.container_title {
	justify-self: flex-start;
}
.container_module {
	width: 85%;
	align-self: center;
	.icon {
		max-width: 24px;
	}
}
.container_body {
	width: 85%;
	-ms-flex-item-align: center;
	align-self: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-evenly;
}
.container_section {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: space-evenly;
}
.image {
	max-width: 10em;
	img {
		width: 100%;
	}
}
.icon {
	max-width: 1.5em;
	margin: 1em;
	img {
		width: 100%;
		padding: 1em;
	}
}
.icon_incidence_date {
	max-width: 1.85em !important;
	margin: 1em 0.7em 1.2em 0.8em;
}
.center {
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}
.btn {
	background-color: $primary_color;
	padding: 0.7em;
	color: $text_accent_color;
	border: 0;
	border-radius: 2em 0 2em 2em;
	-webkit-box-shadow: 0 0.4em 5px rgba(96, 96, 96, 0.2);
	box-shadow: 0 0.4em 5px rgba(96, 96, 96, 0.2);
	-webkit-transition: 0.3s;
	transition: 0.3s;
	font-family: $font-family_1;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 1px;
	a {
		font-size: 1.2em;
		color: $text_accent_color;
	}
	&:hover {
		background-color: $primary_color_hover;
	}
}
.list_elements {
	height: 60%;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: space-evenly;
	a {
		color: $secondary_color_hover;
	}
}
.link_image {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0);
	-webkit-transition: 0.3s;
	transition: 0.3s;
	border-radius: 2em 2em 2em 0;
	cursor: pointer;
	span {
		font-size: 18px;
	}
	&:hover {
		border: 1px solid #707070;
		text-decoration: none;
	}
}
.module-comment-figure {
	font-size: 18px;
}
.rounded_border {
	position: absolute;
	width: 2.2em;
	height: 2.2em;
	margin-left: 0.64em;
	border-radius: 50%;
	#outline: 1px solid rgba(194, 199, 200);
	border: 1px solid rgb(194, 199, 200);
}
a {
	&:hover {
		text-decoration: none;
	}
}
.logo {
	max-height: 1.8em;;
	margin-bottom: 5px;
}

.corpLogo {
	height: 45px;
	margin-bottom: 10px;
}

.slider {
	height: 1px;
}
.metodo_pago {
	border: 1px solid black;
	border-radius: 2em;
}
.letra_doble {
	font-size: 2em;
}
.letra_grande {
	font-size: 4rem;
}
.letra_negrita {
	font-weight: bold;
}
.modal_content {
	text-align: center;
	padding: 0 2em 2em 2em;

}
.p_incident {
	color: $secondary_color;
	font-weight: 400;
	font-size: 18px;
}
.left {
	text-align: left;
}


.centrar_texto {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.ta_incident {
	background-color: $bg_accent_color;
	border: none;
	border-radius: 2em;
	padding: 1em 2em;
	margin-top: 1em;
	margin-bottom: 2em;
	width: 100%;
}
.fin_acuerdo {
	text-align: center;
}
.modal-header {
	border-bottom: 0 none;
	.btn-close {
		margin-left: auto;
		margin-right: 0;
	}
}
.loader {
	text-align: center;
	margin: 4em;
}
.vertical-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.btn-close {
	border: 0;
	position: absolute;
	right: 4px;
	top: 4px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
	background-color: $text_accent_color;
	&:hover {
		opacity: 1;
		background-color: $text_accent_color;
	}
	&:before {
		position: absolute;
		left: 15px;
		content: ' ';
		height: 33px;
		width: 2px;
		background-color: $secondary_color;
		margin-top: -15px;
		transform: rotate(45deg);
	}
	&:after {
		position: absolute;
		left: 15px;
		content: ' ';
		height: 33px;
		width: 2px;
		background-color: $secondary_color;
		margin-top: -15px;
		transform: rotate(-45deg);
	}
	&:focus {
		border: 0;
		outline: 0;
	}
}
.title {
	color: $secondary_color;
	text-align: center;
	font-family: $font-family_2;
	font-size: 36px;
}
.subtitle {
	color: $secondary_color;
	text-align: center;
	font-size: 24px;
	font-family: $font-family_1;
}
.forbidden {
	position: absolute;
	left: 200px;
	top: 80px;
}
.remainingDays {
	color: $primary_color;
	font-weight: bold;
	font-family: $font-family_1;
	font-size: 4em;
	padding: 0;
}
.flex-justify-content {
	display: flex;
	justify-content: space-evenly;
}
@media only screen and (max-width: 420px) {
	.link_image {
		margin-left: -50px;
		width: 120%;
		margin-bottom: -16px;
	}
	.title_menu {
		margin-left: -34px;
	}
}
@media (min-width: 1612px) {
	body {
		font-size: 1.2em;
	}
	.figure {
		img {
			width: 15em;
		}
	}
}
@media (min-width: 2312px) {
	body {
		font-size: 1.4em;
	}
}
@media (min-width: 3412px) {
	body {
		font-size: 2em;
	}
}

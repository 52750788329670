.image-deal {
    width: 13em!important;
}

@media only screen and (max-width: 820px) {
    .module-comment-figure {
        margin-bottom: 3em;
        text-align: center;
    }
}

@media only screen and (max-width: 420px) {
    .image-deal {
        width: 10em!important;
        margin-top: -20px;
        margin-bottom: -10px;
    }

    .subtitulo {
        margin-bottom: 0;
    }
}
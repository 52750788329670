.image-business {
    width: 8em!important;
}

@media only screen and (max-width: 520px) {
    .image-business {
        width: 6em!important;
        margin-bottom: -20px!important;
    }

    .title_module_two {
        font-size: 28px;
    }
}
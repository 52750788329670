.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-top: -20px;
  padding-bottom: 20px;

}

.footerItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: rgba(50, 50, 50, 0.5);
}


.footerLogoItem {
  font-size: 0.6em;
  margin-right: 5px;
  opacity: 0.4;

}
/*# sourceMappingURL=Header.css.map */
.gracias {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.gracias_s1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.gracias_s1 > * {
  margin: 0.5em;
}

.botonera {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.store_boton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background-color: #53575a;
  margin: 1em;
  border-radius: 7px;
  width: 10em;
  height: 3em;
}

.store_boton .icon {
  margin: 0;
}

.store_boton img {
  padding: 0;
}

.store_boton > span {
  color: white;
}

@media only screen and (max-width: 420px) {
    .gracias_s1 {
        margin-left: 42px;
    }

    .gracias_s1 > * {
        margin: 0;
    }

    .gracias_s1 .center {
        margin-left: -84px;
    }

}

/*# sourceMappingURL=Gracias.css.map */
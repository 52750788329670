.title_completed {
    margin-top: 120px!important;
    margin-right: -70px!important;
}

.gracias_s1 {
    margin-bottom: 30px;
}

.waiting_s1 {
    max-width: 600px;
}

.waiting_title {
    font-size: 42px;
}

.btn-pay {
    margin-left: 40px;
    font-size: 16px;
    padding: 0.4em;
    width: 10em;
}

.pay_container h3 {
    font-size: 20px;
}

.figure_complete {
    width: 420px;
}

.figure_smaller {
    width: 350px;
}

.addSpace {
    padding-right: 2em;
}
.addSpace {
    padding-left:20px;
}
.logo_ezpays {
    width: 200px;
    margin-left: 20px;
}

.thanks_for_using {
    margin-left: 100px;
    margin-right: 100px;
}

@media only screen and (max-width: 697px) {
    .figure_complete {
        width: 380px;
    }

    .figure_smaller {
        width: 250px;
    }

    .logo_ezpays {
        width: 150px;
    }

    .forbidden {
        left: 40px;
    }

    .thanks_for_using {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media only screen and (max-width: 540px) {
    .title_completed {
        margin-top: 10px!important;
    }
    .figure_complete {
        width: 300px;
    }

    .figure_smaller {
        width: 150px;
        margin-right: 2em;
    }


    .logo_ezpays {
        width: 120px;
        margin-left: 10px;
    }

    .thanks_for_using {
        font-size: 28px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .waiting_title {
        font-size: 36px;
    }
}

@media only screen and (max-width: 420px) {
    .forbidden {
        left: 12px;
        top: 54px;
    }

    .title_completed {
        font-size: 24px!important;
        margin-right: 20px!important;
    }

    .figure_complete {
        width: 240px;
    }

    .figure_not_resolved {
        width: 240px;

    }

    .figure_spacing {
        padding-left: 2em;
    }

    .thanks_for_using {
        font-size: 20px;
    }

    .logo_ezpays {
        width: 90px;
        margin-left: 5px;
    }
    
    .waiting_title {
        font-size: 32px;
    }

    .forbidden {
        display: none;
    }
}
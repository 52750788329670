@use "sass:color";

$primary_color: rgba(var(--color-principal),1);
$primary_color_hover: rgba(var(--color-principal),0.5);
$secondary_color:  rgba(var(--color-secundario),1);
$secondary_color_hover:  rgba(var(--color-secundario),0.5);
$text_accent_color: white;
$bg_accent_color: rgba(var(--color-secundario),0.1);

$font-family_1: 'Roboto', sans-serif;
$font-family_2: 'Sen', sans-serif;

input, select {
  background-color: #f2f2f2;
  border: none;
  border-radius: 3.5em;
  margin: 0.7em;
  padding: 0.475rem 0.85rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
  width: 100%;
}

input[type=text]:focus, select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

input[type=range] {
  padding: 0;
  height: 3px;
}

.icon-input {
  max-width: 1.6em;
  position: absolute;
  top: 20px;
  left: -16px;
}


.icon-mobile {
  max-width: 1.3em;
  top: 14px;
}

.range-slider__tooltip__label {
  margin-top: -4px;
  font-weight: bold;
}

.amount-debt {
  position: absolute;
  right: 6%;
  margin-top: 4px;
  color: #7e7e7e;
  font-size: 13px;
}

#input-initial-payment {
  background-color: #fff;
  color: rgb(65, 66, 70);
  font-weight: bold;
  width: 170px;
  margin-top: -22px;
  position: absolute;
  height: 22px;
  margin-left: -82px;
  text-align: center;
  border: 2px solid rgba(194, 199, 200, 0.85);
}

/* Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
  #input-initial-payment {
    font-size: 1.1rem;
  }
}

/* Chrome 22-28 */
@media screen and(-webkit-min-device-pixel-ratio:0) {
  #input-initial-payment {-chrome-:only(;
    font-size: 1.1rem;
  );}
}